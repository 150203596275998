import React, { FC } from "react";

type Props = {
  offset: number;
  limit: number;
  totalItems: number;
  currentSize: number;
  setOffset: (value: any) => void;
};

const Paginator: FC<Props> = ({
  offset,
  limit,
  totalItems,
  setOffset,
  currentSize,
}) => {
  // calculate current page
  const currentPage = Math.ceil(Number(offset) / Number(limit)) + 1;

  return (
    <nav
      className="bg-card-500 px-4 py-3 w-full flex items-center justify-between border-t border-gray-200 sm:px-6 justify-self-end flex-shrink-0"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-300">
          Showing <span className="font-medium">{Number(offset) + 1}</span> to{" "}
          <span className="font-medium">
            {Number(offset) + Number(currentSize)}
          </span>{" "}
          of <span className="font-medium">{Number(totalItems)}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <button
          disabled={Number(offset) <= 1}
          onClick={() => setOffset(currentPage - 1)}
          className="relative inline-flex items-center  disabled:cursor-not-allowed px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 disabled:text-gray-300 bg-white hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          disabled={
            Number(totalItems) < Number(limit) ||
            Number(offset) + Number(currentSize) === Number(totalItems)
          }
          onClick={() => setOffset(currentPage + 1)}
          className="ml-3 relative inline-flex items-center  disabled:cursor-not-allowed px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 disabled:text-gray-300 bg-white hover:bg-gray-50"
        >
          Next
        </button>
      </div>
    </nav>
  );
};

export default Paginator;
