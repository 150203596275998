import { wrapClick } from "utils";
import { useFormik } from "formik";
import { Modal, OfficeButton } from "components";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { createAutoFacParticipants } from "_graphql/mutation/auto-fac";
import { AddAutoFacConfigParticipantForm } from "components/forms";
import toast from "react-hot-toast";
import { CreateAutoFacParticipants, CreateAutoFacParticipantsVariables } from "_graphql/mutation/__generated__/CreateAutoFacParticipants";
import { useAppLocation } from "_graphql/cache/auth";

export const valiadationSchema = Yup.object().shape({
  reinsurerId: Yup.array().of(Yup.string().required("Reinsurer is required")),
  autoFacConfigId: Yup.string().required("Auto Fac Configuration is required"),
});

export default function CreateAutoFacParticipantsContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const { params } = useAppLocation();
  const [execute, { loading }] = useMutation<CreateAutoFacParticipants, CreateAutoFacParticipantsVariables>(createAutoFacParticipants, {
    refetchQueries: ["GetAutoFacParticipants"]
  });

  const form = useFormik<CreateAutoFacParticipantsVariables>({
    initialValues: {
      reinsurerId: [],
      autoFacConfigId: params?.id || "",
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables, helpers) => {
      execute({ variables }).then(() => {
        toast.success("Auto Fac Configuration created successfully");
        setOpen(false);
        helpers.resetForm();
        refetch && refetch();
      }).catch((err) => {
        toast.error(err.message);
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });



  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="4xl"
      title="Add Participants"
      description="Add participants to the Auto Fac Configuration"
      renderActions={() => (
        <>

          <OfficeButton
            type="button"
            size="sm"
            disabled={loading}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Please wait ..." : "Add Participants"}
          </OfficeButton>
        </>
      )}
    >
      {/* {JSON.stringify(form.values, null, 2)} */}
      <AddAutoFacConfigParticipantForm form={form} />
    </Modal>
  );
}
