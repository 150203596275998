import { useQuery } from "@apollo/client";
import { GetAutoFacConfig, GetAutoFacConfigVariables } from "_graphql/queries/__generated__/GetAutoFacConfig";
import { GetAutoFacConfiguration, GetAutoFacConfigurationVariables } from "_graphql/queries/__generated__/GetAutoFacConfiguration";
import { GetAutoFacParticipants, GetAutoFacParticipantsVariables } from "_graphql/queries/__generated__/GetAutoFacParticipants";
import { getAutoFacConfig, getAutoFacConfiguration, getAutoFacParticipants } from "_graphql/queries/auto-fac";



export const useAutoFacConfigurations = (variables?: GetAutoFacConfigVariables) => {
    const { data, ...rest } = useQuery<GetAutoFacConfig, GetAutoFacConfigVariables>(getAutoFacConfig, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    });
    return {
        configs: data?.getAutoFacConfig || [],
        length: data?.getAutoFacConfigCount || 0,
        ...rest
    }
}

export const useAutoFacConfigurationParticipants = (variables?: GetAutoFacParticipantsVariables) => {
    const { data, ...rest } = useQuery<GetAutoFacParticipants, GetAutoFacParticipantsVariables>(getAutoFacParticipants, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true
    });
    return {
        configs: data?.getAutoFacParticipants || [],
        length: 0,
        ...rest
    }
}


export const useAutoFacConfiguration = (variables?: GetAutoFacConfigurationVariables) => {
    const { data, ...rest } = useQuery<GetAutoFacConfiguration, GetAutoFacConfigurationVariables>(getAutoFacConfiguration, {
        variables,
        fetchPolicy: "network-only"
    });
    return {
        config: data?.getAutoFacConfiguration,
        ...rest
    }
}