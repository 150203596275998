import { gql } from "@apollo/client";


export const createAutoFacConfiguration = gql`
mutation CreateAutoFacConfiguration($name: String) {
  createAutoFacConfiguration(name: $name)
}
`

export const updateAutoFacConfiguration = gql`
mutation UpdateAutoFacConfiguration($autoFacConfigId: ID!, $name: String) {
  updateAutoFacConfiguration(auto_fac_config_id: $autoFacConfigId, name: $name)
}
`

export const createAutoFacParticipants = gql`
mutation CreateAutoFacParticipants(
  $autoFacConfigId: ID!
  $reinsurerId: [ID!]!
) {
  createAutoFacParticipants(
    auto_fac_config_id: $autoFacConfigId
    reinsurer_id: $reinsurerId
  )
}
`

export const deleteAutoFacConfiguration = gql`
mutation DeleteAutoFacConfiguration($autoFacConfigId: ID!) {
  deleteAutoFacConfiguration(auto_fac_config_id: $autoFacConfigId)
}
`

export const createAutoFacParticipantDeduction = gql`
mutation CreateAutoFacParticipantDeduction(
  $autoFacParticipantId: ID!
  $brokerage: Float!
  $commission: Float!
  $wht: Float!
  $nic: Float!
  $overrider: Float!
) {
  createAutoFacParticipantDeduction(
    auto_fac_participant_id: $autoFacParticipantId
    brokerage: $brokerage
    commission: $commission
    wht: $wht
    nic: $nic
    overrider: $overrider
  )
}
`