import { Modal, OfficeButton } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useFormik } from "formik";
import { useAutoFacConfiguration } from "hooks/data/use-aut-fac";
import { UpdateAutoFacConfiguration, UpdateAutoFacConfigurationVariables } from "_graphql/mutation/__generated__/UpdateAutoFacConfiguration";
import { valiadationSchema } from "./create";
import { updateAutoFacConfiguration } from "_graphql/mutation/auto-fac";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { AutoFacConfigForm } from "components/forms";
import { wrapClick } from "utils";

export default function UpdateAutoFacDetails({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { loading, config } = useAutoFacConfiguration({
        autoFacConfigurationId: searchParams?.id || ""
    })

    const [execute, { loading: updating }] = useMutation<UpdateAutoFacConfiguration, UpdateAutoFacConfigurationVariables>(updateAutoFacConfiguration, {
        refetchQueries: ["GetAutoFacConfig"]
    });

    const form = useFormik<any>({
        initialValues: {
            name: "",
            autoFacConfigId: searchParams?.id || ""
        },
        validationSchema: valiadationSchema,
        onSubmit: async (variables, helpers) => {
            execute({ variables }).then(({ data }) => {
                if (data?.updateAutoFacConfiguration) {
                    toast.success("Auto Fac Configuration updated successfully");
                    setOpen(false);
                    helpers.resetForm();
                } else {
                    toast.error("Failed to update Auto Fac Configuration");
                }
            }).catch((err) => {
                toast.error(err.message);
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (config) {
            form.setFieldValue("name", config.name || "");
        }
    }, [config]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            // size="6xl"
            title="Update Auto Fac Details"
            description="Update the details of Auto Fac below"
            renderActions={() => (
                <>

                    <OfficeButton
                        type="button"
                        size="sm"
                        loading={updating}
                        disabled={updating}
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {updating ? "Please wait ..." : "Update Auto Fac Configuration"}
                    </OfficeButton>
                </>
            )}
        >

            <AutoFacConfigForm form={form} />
        </Modal>
    );
}
