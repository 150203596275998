import { Broker_broker } from '_graphql/queries/__generated__/Broker'
import { GetAutoFacConfig, GetAutoFacConfig_getAutoFacConfig } from '_graphql/queries/__generated__/GetAutoFacConfig'
import { GetAutoFacConfiguration_getAutoFacConfiguration } from '_graphql/queries/__generated__/GetAutoFacConfiguration'
import { Avatar } from 'components/core'
import _ from 'lodash'
import { FC } from 'react'

type Props = {
    config: GetAutoFacConfiguration_getAutoFacConfiguration | null | undefined
}

const AutoFacView: FC<Props> = ({ config }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Auto Fac Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='col-span-3'>
                        <span className="block text-sm font-light text-gray-700">
                            Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {config?.name || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Created By</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Employee Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {config?.employee?.employee_first_name || "N/A"} {config?.employee?.employee_last_name || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Adddress
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {config?.employee?.employee_email || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AutoFacView