import { CreateAutoFacConfigurationVariables } from "_graphql/mutation/__generated__/CreateAutoFacConfiguration";
import { TextInput } from "components/core";
import { FormikProps } from "formik";
import { FC } from "react";

export interface AutoFacConfigFormProps {
  form: FormikProps<CreateAutoFacConfigurationVariables>;
}

const AutoFacConfigForm: FC<AutoFacConfigFormProps> = ({ form }) => {
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div className="">
        <span className="text-xs font-light">Auto Fac Config Details</span>
        <div className="grid grid-cols-2 gap-2 pt-6">
          <div className="col-span-2">
            <TextInput
              id="name"
              label="Auto Fac Config Name"
              type="text"
              placeholder="e.g. First Auto Fac Config"
              required={true}
              {...form}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoFacConfigForm;
