import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import { SendEmailForm } from "components/forms";
import { useOffer } from "hooks/data/use-offers";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import { sendClaimNotification } from "_graphql/mutation/claims";
import { useEffect } from "react";
import {
  SendClaimNotification,
  SendClaimNotificationVariables,
} from "_graphql/mutation/__generated__/SendClaimNotification";
import * as Yup from "yup";

export const valiadationSchema = Yup.object().shape({
  message: Yup.string().required("Message is required"),
  subject: Yup.string().required("Subject is required"),
});

export default function NotifyContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [rate, setRate] = useUrlState("rate");
  const { offer, loading: fetching } = useOffer({
    offer_id: searchParams?.id || "",
  });
  const [send, { loading }] = useMutation<
    SendClaimNotification,
    SendClaimNotificationVariables
  >(sendClaimNotification);

  const form = useFormik<any>({
    initialValues: {
      copied_emails: [],
      subject: "",
      message: "",
      include_attachment: false,
      should_send: 0,
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables) => {
      await send({
        variables: {
          emailData: {
            offer_id: searchParams?.id || "",
            copied_emails: variables.copied_emails?.map((el: any) => el) || [],
            message_content: variables.message,
            attachment:
              variables.attachments?.map((el: any) => el?.file_path || "") ||
              [],
            subject: variables.subject,
          },
          // include_attachment: variables.include_attachment,
          // should_send: variables.should_send,
        },
      }).then(({ data }) => {
        if (data?.sendClaimNotification) {
          toast.success("Email Sent Successfully");
          setOpen(false);
          refetch?.();
        }
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });

  useEffect(() => {
    if (form.values.rate) {
      setRate(form.values.rate);
    } else {
      setRate(1);
    }
  }, [form.values.rate, rate, setRate]);
  useEffect(() => {
    if (offer?.offer_detail?.currency) {
      form.setFieldValue("currency", offer?.offer_detail?.currency);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer?.offer_detail?.currency]);

  return (
    <Modal
      open={open}
      setOpen={(value) => {
        setOpen(value);
        form.resetForm();
      }}
      size="4xl"
      loading={fetching}
      title="Claim Request"
      description="Notify Insurer of a claim"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Sending Email..." : "Send Email"}
          </button>
        </>
      )}
    >
      <SendEmailForm form={form} />
    </Modal>
  );
}
