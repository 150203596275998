import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import { GetAutoFacConfig_getAutoFacConfig } from "_graphql/queries/__generated__/GetAutoFacConfig";
import { ActionButton, OfficeButton, OfficeHeader, Paginator } from "components";
import Table from "components/tables/table";
import { usePagination } from "hooks";
import { useAutoFacConfigurations } from "hooks/data/use-aut-fac";
import { FC } from "react";
import ViewAutoFacDetails from "./view";
import { Action } from "components/buttons/action-button";
import UpdateAutoFacDetails from "./update";
import CreateAutoFacContainer from "./create";
import RemoveContainer from "./remove";

type AutoFacAconfigationsPageProps = {};

const AutoFacAconfigationsPage: FC<AutoFacAconfigationsPageProps> = () => {
    const { offset, limit, setPage } = usePagination();
    const navigate = useAppNavigate();
    const searchParams = useAppSearch()
    const { length, configs, refetch, loading } = useAutoFacConfigurations({
        limit: limit,
        offset: offset
    });


    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    const navigateToParticipants = (id: string) => () => {
        navigate({
            to: `./${id}/participants`,
        });
    };

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader renderActions={() => (
                <OfficeButton onClick={() => navigate({
                    search(prev) {
                        return {
                            ...prev,
                            modal: "create"
                        }
                    },
                })}>
                    Create Auto Fac
                </OfficeButton>
            )} />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <Table title={""}
                        loading={loading}
                        columns={[
                            {
                                name: "Name",
                                accessor: "name",
                                render: (item: GetAutoFacConfig_getAutoFacConfig) => (
                                    <div className="flex items-center space-x-2">
                                        <Cog6ToothIcon className="h-6 w-6" />
                                        <div className="flex-1">
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            },
                            {
                                name: "Created By",
                                accessor: "employee.employee_first_name",
                            },
                            {
                                name: "Actions",
                                accessor: "created_at",
                                render: (item: GetAutoFacConfig_getAutoFacConfig) => (
                                    <>
                                        <ActionButton action="expand" onClick={navigateToParticipants(item?.auto_fac_config_id?.toString() || "")} />
                                        <ActionButton action="view" onClick={dispatchAction(item?.auto_fac_config_id?.toString() || "", "view")} />
                                        <ActionButton action="update" onClick={dispatchAction(item?.auto_fac_config_id?.toString() || "", "update")} />
                                        <ActionButton action="remove" extraClassName="text-red-400" onClick={dispatchAction(item?.auto_fac_config_id?.toString() || "", "remove")} />
                                    </>
                                )
                            },
                        ]} data={configs} refetch={refetch}
                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={length} currentSize={configs.length} setOffset={setPage} />}

                    />
                </div>
            </div>
            <CreateAutoFacContainer open={searchParams?.modal === "create"} setOpen={(value) => {
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            modal: value ? "create" : undefined
                        }
                    },
                })
            }} />
            {searchParams?.id && (
                <>
                    <ViewAutoFacDetails open={searchParams?.modal === "view"} setOpen={(value) => {
                        navigate({
                            search(prev) {
                                return {
                                    ...prev,
                                    id: value ? searchParams?.id : undefined,
                                    modal: value ? "view" : undefined
                                }
                            },
                        })
                    }} />
                    <UpdateAutoFacDetails open={searchParams?.modal === "update"} setOpen={(value) => {
                        navigate({
                            search(prev) {
                                return {
                                    ...prev,
                                    id: value ? searchParams?.id : undefined,
                                    modal: value ? "update" : undefined
                                }
                            },
                        })
                    }} />
                    <RemoveContainer open={searchParams?.modal === "remove"} setOpen={(value) => {
                        navigate({
                            search(prev) {
                                return {
                                    ...prev,
                                    id: value ? searchParams?.id : undefined,
                                    modal: value ? "remove" : undefined
                                }
                            },
                        })
                    }} />
                </>
            )}
        </main>
    )
};

export default AutoFacAconfigationsPage;
