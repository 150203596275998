import { wrapClick } from "utils";
import { useFormik } from "formik";
import { Modal, OfficeButton } from "components";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CreateAutoFacConfiguration, CreateAutoFacConfigurationVariables } from "_graphql/mutation/__generated__/CreateAutoFacConfiguration";
import { createAutoFacConfiguration } from "_graphql/mutation/auto-fac";
import { AutoFacConfigForm } from "components/forms";
import toast from "react-hot-toast";

export const valiadationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

export default function CreateAutoFacContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {

  const [execute, { loading }] = useMutation<CreateAutoFacConfiguration, CreateAutoFacConfigurationVariables>(createAutoFacConfiguration, {
    refetchQueries: ["GetAutoFacConfig"]
  });

  const form = useFormik<CreateAutoFacConfigurationVariables>({
    initialValues: {
      name: "",
    },
    validationSchema: valiadationSchema,
    onSubmit: async (variables, helpers) => {
      execute({ variables }).then(() => {
        toast.success("Auto Fac Configuration created successfully");
        setOpen(false);
        helpers.resetForm();
        refetch && refetch();
      }).catch((err) => {
        toast.error(err.message);
      });
    },
    onReset: () => {
      setOpen(false);
    },
  });



  return (
    <Modal
      open={open}
      setOpen={setOpen}
      // size="4xl"
      title="Create Auto Fac"
      description="provide the details of the Auto Fac below"
      renderActions={() => (
        <>

          <OfficeButton
            type="button"
            size="sm"
            disabled={loading}
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Please wait ..." : "Create Auto Fac Configuration"}
          </OfficeButton>
        </>
      )}
    >
      <AutoFacConfigForm form={form} />
    </Modal>
  );
}
