import { AutoFacView, LanguagePicker, Modal, PDFRenderer } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import { useFormik } from "formik";
import { useAutoFacConfiguration } from "hooks/data/use-aut-fac";

export default function ViewAutoFacDetails({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { loading, config } = useAutoFacConfiguration({
        autoFacConfigurationId: searchParams?.id || ""
    })
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            // size=""
            title="View Auto Fac Details"
            description="Details of Auto Fac are shown below"
        >

            <AutoFacView config={config} />
        </Modal>
    );
}
