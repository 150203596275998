import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useAppLocation, useAppNavigate, useAppSearch } from "_graphql/cache/auth";
import { GetAutoFacConfig_getAutoFacConfig } from "_graphql/queries/__generated__/GetAutoFacConfig";
import { ActionButton, OfficeButton, OfficeHeader, Paginator } from "components";
import Table from "components/tables/table";
import { usePagination } from "hooks";
import { useAutoFacConfigurationParticipants } from "hooks/data/use-aut-fac";
import { FC } from "react";
import CreateAutoFacParticipantsContainer from "./create";

type AutoFacAconfigationsParticipantsPageProps = {};

const AutoFacAconfigationsParticipantsPage: FC<AutoFacAconfigationsParticipantsPageProps> = () => {
    const { params } = useAppLocation()
    const searchParams = useAppSearch()
    const navigate = useAppNavigate();
    const { offset, limit, setPage, setLimit } = usePagination();
    const { length, configs, refetch, loading } = useAutoFacConfigurationParticipants({
        autoFacConfigId: Number(params?.id) || 0,
        limit: limit,
        offset: offset
    })
    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader renderActions={() => (
                <OfficeButton onClick={() => navigate({
                    search(prev) {
                        return {
                            ...prev,
                            modal: "create"
                        }
                    },
                })}>
                    Add Participants
                </OfficeButton>
            )} />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <Table title={params?.id || ""}
                        loading={loading}
                        hasSearch
                        columns={[
                            {
                                name: "Name",
                                accessor: "name",
                                render: (item: GetAutoFacConfig_getAutoFacConfig) => (
                                    <div className="flex items-center space-x-2">
                                        <Cog6ToothIcon className="h-6 w-6" />
                                        <div className="flex-1">
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            },
                            {
                                name: "Created By",
                                accessor: "employee.employee_first_name",
                            },
                            {
                                name: "Actions",
                                accessor: "created_at",
                                render: () => (
                                    <>
                                        <ActionButton action="view" onClick={() => { }} />
                                        <ActionButton action="update" onClick={() => { }} />
                                        <ActionButton action="remove" extraClassName="text-red-400" onClick={() => { }} />
                                    </>
                                )
                            },
                        ]} data={configs} refetch={refetch}
                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={length} currentSize={configs.length} setOffset={setPage} />}

                    />
                </div>
            </div>
            <CreateAutoFacParticipantsContainer open={searchParams?.modal === "create"} setOpen={(value) => {
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            modal: value ? "create" : undefined
                        }
                    },
                })
            }} />
        </main>
    )
};

export default AutoFacAconfigationsParticipantsPage;
