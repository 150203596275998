import { Broker_broker } from '_graphql/queries/__generated__/Broker'
import { Avatar } from 'components/core'
import _ from 'lodash'
import { FC } from 'react'

type Props = {
    broker: Broker_broker | null | undefined
}

const BrokerView: FC<Props> = ({ broker }) => {
    return (
        <div className="space-y-6 divide-y divide-gray-200">
            <div>
                <span className='text-xs font-light'>Company Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div className='row-span-2 flex flex-col space-y-3 items-center justify-center'>
                        <Avatar
                            alt={broker?.re_broker_name || "N A"}
                            size="xl"
                        />
                    </div>
                    <div className='col-span-3'>
                        <span className="block text-sm font-light text-gray-700">
                            Company Name
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_name || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Contact Information</span>
                <div className='grid grid-cols-3 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Email Address
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_email || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Website
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_website || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
            <div className='pt-6'>
                <span className='text-xs font-light'>Address Information</span>
                <div className='grid grid-cols-4 gap-6 mt-2'>
                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            County
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_address?.country || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block text-sm font-light text-gray-700">
                            Region
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_address?.region || "N/A"}
                        </div>
                    </div>

                    <div>
                        <span className="block col-span-3 text-sm font-light text-gray-700">
                            Street
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_address?.street || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block col-span-3 text-sm font-light text-gray-700">
                            City
                        </span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {broker?.re_broker_address?.city || "N/A"}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BrokerView