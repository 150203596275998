import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { DeleteAutoFacConfiguration, DeleteAutoFacConfigurationVariables } from "_graphql/mutation/__generated__/DeleteAutoFacConfiguration";
import { deleteAutoFacConfiguration } from "_graphql/mutation/auto-fac";

export default function RemoveContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    DeleteAutoFacConfiguration,
    DeleteAutoFacConfigurationVariables
  >(deleteAutoFacConfiguration, {
    refetchQueries: ["GetAutoFacConfig"],
  });

  const handleSubmit = async () => {
    await remove({
      variables: {
        autoFacConfigId: searchParams?.id || "",
      },
    }).then(({ data }) => {
      if (data?.deleteAutoFacConfiguration) {
        toast.success("Auto Fac Removed Successfully");
        refetch?.();
        setOpen?.(false);
      } else {
        toast.error("Could not remove Auto Fac");
      }
    }).catch((err) => {
      toast.error(err.message);
    });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Delete Auto Fac"
      cancelText="No, Don't Delete Auto Fac"
      description="This action would completely remove Auto Fac details from system"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading ? "Removing Auto Fac..." : "Remove Auto Fac"}
          </button>
        </>
      )}
    />
  );
}
